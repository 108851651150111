//add
$("#button-add-item").click(function(){

  $.post("/personal/cart/ajax.php",
  {
     dataType: "json",
    'method':   'add',
    'itemId':   $("#item-id").html(),
    'quantity': $("#item-quantity").html()
  },
  function(responseAJAX, statusAJAX){
      console.log({
        status:   statusAJAX,
        response: $.parseJSON( responseAJAX )
      });

      if ( statusAJAX === 'success' ) {
        $("#button-add-item").addClass('no-active').attr( 'disabled', 'disabled' );
        $(".less-more-buttons-1-js .button").attr( 'disabled', 'disabled' );
      }
  });
});
//add
