/*Prevent default*/

$('#find-1-open-close').click(function( event ) {
    event.preventDefault();
});

$('#form-search-1').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if ( keyCode === 13 ) {
        e.preventDefault();
        return false;
    }
});

$('#form-search-1-submit').click(function( event ) {
    event.preventDefault();
});

$('#form-search-1-query').attr('autocomplete', 'off');

/*Prevent default*/
 

/*When change input value*/
$('#form-search-1-query').keyup(function(){

    var query = $(this).val();

    if ( query.length == 0 ) {
        $('#find-1-results').collapse('hide');
    }

    if ( query.length > 0 ) {
        $('#find-1-results').collapse('show');
    }

});
/*When change input value*/


/*For close find-1*/
$( document ).on( 'click', function(e) {

    if ( $('#find-1').css('opacity') === '0' ) {
        return true;
    }

    if ( $(e.target).parent().attr('id') === 'find-1-open-close' ) {
        return true;
    }

    if ( $(e.target).attr('id') === 'form-search-1-query' ) {
        return true;
    }

    if ( $(e.target).attr('id') === 'form-search-1-submit' ) {
        return true;
    }

    if ( $('#find-1-results').hasClass('show') ) {

        $('#find-1-results').collapse('hide');

        $('#find-1-results').on('hidden.bs.collapse', function () {
            close();
        })

    } else {
        close();
    }


    /*function close*/
    function close() {
        $('#find-1').css({
            'opacity':  '0'
        });

        setTimeout(
            function()
            {
                $('#find-1').css({
                    'top':      '-500px'
                });

            }, 200);
    }
    /*function close*/

});
/*For close find-1*/


/*For open find-1*/
$('#find-1-open-close').click(function( event ) {

    $('#find-1').css({
        'top':      '0px',
        'opacity':  '1'
    });
    $('#form-search-1-query').focus();

});
/*For open find-1*/
