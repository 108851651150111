var evenvaGlobalObj = {

  lessmoreButtonClick: function (e) {
    var target = e.target;

    if ( $(".basket-list-item  .button").length !== 0 ) {
      basketListItemUpdateAjax(e);
    }
  }

};
