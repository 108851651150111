
//update
function basketListItemUpdateAjax(e) {

      var basketListItem  = $(e.target).parents('.basket-list-item'),
          id              = basketListItem.find('.cart-item-id').html(),
          quantity        = basketListItem.find('.cart-item-quantity').html(),
          days            = basketListItem.find('.cart-item-days').html();

      $.post("/personal/cart/ajax.php",
      {
         dataType: "json",
        'method':   'update',
        'itemId':   id,
        'quantity': quantity,
        'days':     days,
      },
      function(responseAJAX, statusAJAX){
          console.log({
            status:   statusAJAX,
            response: $.parseJSON( responseAJAX )
          });
      });
}
//update


//delete
$(".delete-js").click(function(e){

  $.post("/personal/cart/ajax.php",
  {
     dataType: "json",
    'method':   'delete',
    'itemId':   $(e.target).parents('.basket-list-item').find('.cart-item-id').html()
  },
  function(responseAJAX, statusAJAX){
      console.log({
        status:   statusAJAX,
        response: $.parseJSON( responseAJAX )
      });
  });
});
//delete
