$('.single-catalog-item-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.single-catalog-item-slider-nav'
});
$('.single-catalog-item-slider-nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.single-catalog-item-slider',
    dots: false,
    arrows: false,
    // centerMode: true,
    focusOnSelect: true
});
