/*Buttons -/+ */
$( document ).ready(function() {

    if( $('.less-more-buttons-1-js').length !== 0 ) {

        var LessMoreButtonsObjs = $('.less-more-buttons-1-js');

        $.each( LessMoreButtonsObjs, function( key, LessMoreButtonsObj ) {

            var valueObj       = $( LessMoreButtonsObj ).find('.value-js');
            var value          = valueObj.html()*1;                             //number

            var buttonLess  = $( LessMoreButtonsObj ).find('.less-js');
            var buttonMore  = $( LessMoreButtonsObj ).find('.more-js');

            var minValue = 1;                                                   //number
            var maxValue = 5;                                                   //number


            /*
                 when page has first load buttonLess has class deactive and NOT has class active

                 when user click to buttonMore ... and then value === maxValue {

                     script must remove class active and add class deactive for buttonMore
                                     and remove class deactive and add class active for buttonLess

                 }
            */


            buttonLess.click(function(e){

                if ( value > minValue   ) {
                    value = value - 1;
                    valueObj.html( value );
                }

                buttonMore.addClass('active');

                if ( value === minValue ) {
                    buttonLess.addClass('deactive');
                    buttonLess.removeClass('active');

                    buttonMore.addClass('active');
                    buttonMore.removeClass('deactive');

                } else {
                    buttonMore.addClass('active');
                    buttonMore.removeClass('deactive');
                }

                // console.log(e.target);
                evenvaGlobalObj.lessmoreButtonClick(e);
            });


            buttonMore.click(function(e){

                if ( value < maxValue ) {
                    value = value + 1;
                    valueObj.html( value );
                }

                buttonLess.addClass('active');

                if ( value === maxValue ) {
                    buttonMore.addClass('deactive');
                    buttonMore.removeClass('active');

                    buttonLess.addClass('active');
                    buttonLess.removeClass('deactive');

                } else {
                    buttonLess.addClass('active');
                    buttonLess.removeClass('deactive');
                }

                // console.log(e.target); 
                evenvaGlobalObj.lessmoreButtonClick(e);
            });


        });

    }

});
/*Buttons -/+ */
